<template>
  <div
    :style="{ height: screeHeight-100 + 'px' }"
    class="info-warp"
  >
    <div style="color: #323233;position: absolute;width: 92%;margin: 10px 4%;">
        <div class="wrap">
            <div class="top-box">
                <div style="padding: 15px; ">
                    <strong style="font-size: 16px;font-weight: bold;color: black">{{ order.statusText }}</strong>
                    <!-- <span>{{ order.statusText }}</span> -->
                    <div style="float: right;color: #ee0a24;font-size: 19px;font-weight: bold;">￥{{ order.actualPayFee }}</div>
                </div>
                <div style="padding: 5px 15px;font-size: 15px;color: #4a4a4a; ">
                    下单时间：{{ order.createTime }}
                </div>
                <p></p>
            </div>
            <div class="bottom-box">
                <div style="padding: 5px 15px; ">
                    订单号：{{ order.orderNo }}
                </div>
                <div  style="padding: 5px 15px;font-size: 15px;color: #4a4a4a; ">
                    <!-- v-for="item in order.ticketList" :key="item.itemId" -->
                    <div ><span style="color: #4a4a4a;">取票号：</span>
                    <span style="margin-left: 15%;color: #ff6034;font-size: 18px;">{{ order.getTicketNo }}</span>
                        <div style="display: flex;justify-content: center;padding: 15px;padding-bottom: 55px;">
                            <img :src="`${mydata.baseURL}qrcode?code=`+order.getTicketNo" :height="130" :width="130"/>
                        </div>
                    </div>
                </div>
                <p></p>
            </div>
        </div>
        <!-- 票类型/核销 -->
        <div class="wrap" style="margin-top: 10px;" v-for="item in order.itemList" :key="item.itemId">
            <div class="top-box">
                <div style="padding: 15px; ">
                    <strong style="font-size: 16px;font-weight: bold;color: black">{{ item.productName }}</strong>
                    <div style="float: right"><van-icon name="arrow-up" /></div>
                </div>
                <div style="padding: 5px 15px; font-size: 15px;color: #4a4a4a; ">
                    门票类型： {{ item.ticketType }}
                </div>
                <p></p>
            </div>
            <div class="bottom-box" >
                <div >
                    <div style="padding: 5px 15px;font-size: 15px;color: #4a4a4a;  ">
                        <div style="font-size: 16px;font-weight: bold;color: black;padding: 8px 0;">核销凭证：{{ticketShow+1}}/{{ item.ticketList.length }}</div>
                        <div style="padding: 5px 0;">游玩时间：{{ nowticket.playDate }}</div>
                        <div style="padding: 5px 0;">预约时段：{{ nowticket.appointmentStartTime }} - {{nowticket.appointmentEndTime}}</div>
                        <div style="padding: 5px 0;">门票状态：{{ nowticket.ticketStatusText }}</div>
                        <div style="padding: 5px 0;">有效期：{{ nowticket.validStartDate }} - {{nowticket.validEndDate}}</div>
                    </div>
                    <div style="padding: 5px 15px;font-size: 15px;  ">
                        <div>
                            <div style="color: #4a4a4a;text-align: center;">票号：{{ nowticket.ticketNo }}</div>
                            <div style="display: flex;justify-content: center;padding: 15px;padding-bottom: 35px;justify-content: center;align-items: center;font-size: 29px;">
                                <van-icon name="arrow-left" @click="toTicketShow('out',item.ticketList)" style="padding-right: 15px;"/>
                                <img :src="`${mydata.baseURL}qrcode?code=`+nowticket.ticketNo" :height="130" :width="130"/>
                                <van-icon name="arrow" @click="toTicketShow('add',item.ticketList)" style="padding-left: 15px;"/>
                            </div>
                        </div>
                    </div>
                    <div style="height: 35px;">
                        <span v-if="nowticket.ticketStatus == 1" @click="toRefundTicket(nowticket.ticketNo)" class="subbtn" style="background-color: snow;color:#ff6666;;border: 1px solid;float: right;padding: 5px 9px;" >
                        申请退款</span>
                    </div>
                    <p></p>
                </div>
            </div>
        </div>
        <!-- 付款信息 -->
        <div class="wrap" style="border-top: 15px solid #f5f5f5;">
            <div class="top-box">
                <div style="padding: 15px; ">
                    <strong style="font-size: 16px;font-weight: bold;color: black;padding: 10px 0px;">付款信息</strong>
                    <div style="font-size: 15px;color: #4a4a4a;  ">
                        <div style="padding: 5px 0;">订单编码：{{ order.orderNo }}</div>
                        <div style="padding: 5px 0;">订单金额：￥{{ order.totalFee }}</div>
                        <div style="padding: 5px 0;">实付金额：￥{{ order.actualPayFee }}</div>
                        <!-- <div style="padding: 5px 0;">支付方式：</div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 出游人信息 -->
        <!-- <div class="wrap" style="margin-top: 10px;">
            <div class="top-box">
                <p></p>
            </div>
            <div class="bottom-box">
                <div  style="padding: 5px 15px;font-size: 15px;color: #4a4a4a;  ">
                    <div style="font-size: 16px;font-weight: bold;color: black;padding: 8px 0;">出游人信息</div>
                    <div >
                        <div style="padding: 5px 0;">游玩时间：{{ order.createTime }}</div>
                        <div style="padding: 5px 0;">预约时段：{{ order.createTime }}</div>
                        <div style="padding: 5px 0;">门票状态：{{ order.createTime }}</div>
                    </div>
                    <div style="height: 35px;"><span class="subbtn" style="background-color: snow;color: rgb(255 132 146);;border: 1px solid;float: right;" >申请退款</span></div>
                </div>
                <p></p>
                <div style="padding: 5px 15px;font-size: 15px;color: #4a4a4a;border-top: 5px solid #dcdee0;">
                    <div style="font-size: 16px;font-weight: bold;color: black;padding: 8px 0;">优惠平日票</div>
                    <div >
                        <div style="padding: 5px 0;">门票类型：{{ order.createTime }}</div>
                        
                    </div>
                </div>
                <div style="padding: 5px 15px;font-size: 15px;color: #4a4a4a;border-top: 5px solid #dcdee0;">
                    <div style="font-size: 16px;font-weight: bold;color: black;padding: 8px 0;">购买人信息</div>
                    <div >
                        <div style="padding: 5px 0;">游玩时间：{{ order.createTime }}</div>
                        <div style="padding: 5px 0;">预约时段：{{ order.createTime }}</div>
                        <div style="padding: 5px 0;">门票状态：{{ order.createTime }}</div>
                    </div>
                </div>
                <div style="padding: 5px 15px;font-size: 15px;color: #4a4a4a;border-top: 5px solid #dcdee0;">
                <div style="font-size: 16px;font-weight: bold;color: black;padding: 8px 0;">付款信息</div>
                <div >
                    <div style="padding: 5px 0;">游玩时间：{{ order.createTime }}</div>
                    <div style="padding: 5px 0;">预约时段：{{ order.createTime }}</div>
                    <div style="padding: 5px 0;">门票状态：{{ order.createTime }}</div>
                </div>
                <div style="height: 25px;"><span class="subbtn" style="background-color: snow;color: rgb(255 132 146);;border: 1px solid;float: right;" >申请退款</span></div>
            </div>
            </div>
        </div> -->
    </div>
  </div>
</template>
<script>
// import qrcode from '../../components/qrCode';
import { Toast } from 'vant';
export default {
  name: "index",
//   components:{
//       qrcode
//   },
  data() {
    return {
      searchValue: "",
      screenWidth: document.documentElement.clientWidth, // 屏幕宽
      screeHeight: document.documentElement.clientHeight, // 屏幕高
      list: [],
      orderNo:'',
      order:{},
      isCombination:false, //是否组合套票，组合套票不在此页面展示检票码
      ticketShow:0,
      nowticket:{},
    };
  },
  created() {
      this.orderNo = this.$route.query.orderNo
      this.queryOrderInfo()
  },
  mounted() {
      
  },
  methods: {
    getticket(ticketList){
        this.nowticket = ticketList[this.ticketShow]
    },
    toRefundTicket(ticketNo){
        this.$loading.show()
        this.$http({
            method: "POST",
            url: `${this.apis.refundTicket}?ticketNo=${ticketNo}`,
        }).then((res) => {
            this.$loading.hide()
            if (res.code == 200) {
                Toast({
                    message: '退票成功~',
                    icon: 'success',
                });
                this.$router.go(-1)
            }
            Toast({
                message: res.message,
                icon: 'fail',
            });
        })
    },
    toTicketShow(type,ticketList){
        if(type === 'out'){
            if(this.ticketShow===0){
                return
            }
            this.ticketShow--
        }else{
            if(this.ticketShow===ticketList.length){
                return
            }
            this.ticketShow++
        }
        this.getticket(ticketList)
        this.$forceUpdate()
    },
    queryOrderInfo() {
        this.$loading.show()
        this.$http({
            method: "POST",
            url: `${this.apis.orderDetail}?orderNo=${this.orderNo}`,
        }).then((res) => {
            this.$loading.hide()
            if (res.code == 200) {
                this.order = res.data;
                let ticketStatus = ['','未使用','已使用','已退票','退票中','交易取消']
                this.order.itemList.map(item => {
                    item.ticketList = []
                    this.order.ticketList.map(ite => {
                        ite.ticketStatusText = ticketStatus[ite.ticketStatus]
                        if(ite.itemId === item.itemId){
                            item.ticketList.push(ite)
                        }
                    })
                });
                console.log(this.order)
                let oldorder = JSON.parse(localStorage.getItem('orderInfo'))
                this.order = Object.assign(oldorder, this.order)
                let status = ['','未支付','已支付','已退单','已核销','已取消']
                let sta = this.order.status
                sta = (sta==2||sta==3) ? 2 : sta
                this.order.statusText=status[sta]
                this.nowticket = this.order.itemList[0].ticketList[this.ticketShow]
            }
        });
    },
  },
};
</script>
<style >
    .info-wrap{
        background-image: linear-gradient(to top, hsl(0deg 0% 100% / 100%) 0%, #FF5421 100%);
        position: relative;
    }
    p{
      margin:0;
      padding:0;
    }
    .top-box{
        width:100%;
        /* height:200px; */
        background: #fff;
        position: relative;
        margin-bottom:20px;
        border-radius: 4px 4px 0 0;
    }
    .bottom-box{
        width:100%;
        /* height:100px; */
        background: #fff;
        position: relative;
        border-radius: 0 0 4px 4px;
    }
    .top-box p,.bottom-box p{
        width: calc(100% - 20px);
        height:10px;
        position:absolute;
        left: 10px;
    }
    .top-box p{
        background: #fff;
        bottom: -10px;
        border-bottom: 4px solid #efefef;
    }
    .bottom-box p{
        background: #fff;
        top: -10px;
    }
    .top-box p::before,.bottom-box p::before{
        content:'';
        position:absolute;
        width: 10px;
        height: 10px;
        left:-10px;
    }
    .top-box p::after,.bottom-box p::after{
        content:'';
        position:absolute;
        width: 10px;
        height: 10px;
        right:-10px;
    }
    .top-box p::before{
        background-image: radial-gradient(circle 8px at 0 100%,  transparent 50px, #fff 50%);
    }
    .top-box p::after{
        background-image: radial-gradient(circle 8px at 100% 100%,  transparent 50px, #fff 50%);
    }
    .bottom-box p::before{
        background-image: radial-gradient(circle 10px at 0 0,  transparent 50px, #fff 50%);
    }
    .bottom-box p::after{
        background-image: radial-gradient(circle 10px at 100% 0,  transparent 50px, #fff 50%);
    }
    .subbtn {
  background-color: #fd1a34;
  color: white;
  text-align: center;
  border-radius: 15px;
  padding: 5px;
  margin-left: 8px;
}

</style>


